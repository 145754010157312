<template>
  <div>
    <div
      v-for="item in visibleItems"
      :key="item.message.id"
      class="alert"
      :class="`alert-${item.message.level}`">
      <div class="d-flex">
        <div>
          <FlexTextIcon :icon="item.icon">
            {{ item.message.message }}
          </FlexTextIcon>
        </div>
        <div class="ms-auto">
          <a
            class="text-muted"
            href="#"
            :title="$t('button.close')"
            @click.prevent="hideItem(item)">
            <SimpleIcon icon="times"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {type PortalMessage, type PortalMessageLevel, PortalMessagesApi} from '@apispec/portal-messages';
import FlexTextIcon from '@components/FlexTextIcon.vue';
import {LoaderState, loadLoaderStateData} from '@components/loader/LoaderState';
import SimpleIcon from '@components/SimpleIcon.vue';
import {createApiInstance} from '@plugins/api/api';
import {useAuthenticator, useIsAuthenticated} from '@plugins/auth/auth';
import {useHttp} from '@plugins/http/http';
import {getFromLocalStorage, setInLocalStorage} from '@plugins/localStorage';
import {isAfter} from 'date-fns';
import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, watch} from 'vue';

const iconForLevel = (level: PortalMessageLevel): string => {
  switch (level) {
    case 'info':
      return 'fa-info-circle';
    case 'warning':
      return 'fa-exclamation-circle';
    case 'danger':
      return 'fa-times-circle';
  }
};

const numberForLevel = (level: PortalMessageLevel): number => {
  switch (level) {
    case 'info':
      return 1;
    case 'warning':
      return 2;
    case 'danger':
      return 3;
  }
};

type PortalMessageItem = { visible: boolean, icon: string, message: PortalMessage };
const STORAGE_KEY = 'hidden_portal_message_ids';

export default defineComponent({
  components: {FlexTextIcon, SimpleIcon},
  setup: () => {
    // Use clean http instance without default error handling, but with authentication
    const http = useHttp(true);
    const authenticator = useAuthenticator();
    authenticator.addHttpAuth(http);

    const api = createApiInstance(PortalMessagesApi, http);
    const isAuthenticated = useIsAuthenticated();

    const messages = reactive<{ [id: number]: PortalMessageItem }>({});
    const messageLoaderState = reactive(new LoaderState());

    let hiddenIds: number[] = JSON.parse(getFromLocalStorage(STORAGE_KEY) ?? '[]');

    let pollInterval: number;
    let visibleInterval: number;
    const startInterval = () => {
      clearInterval(visibleInterval);
      clearInterval(pollInterval);
      visibleInterval = setInterval(() => checkVisibility(), 60 * 1000); // 1 minute in milliseconds
      pollInterval = setInterval(() => loadMessages(), 10 * 60 * 1000); // 10 minutes in milliseconds
    };

    const checkVisibility = () => Object.values(messages).forEach(checkVisible.bind(this));
    const checkVisible = (message: PortalMessageItem) => {
      if (!message.visible || !message.message.end) {
        return;
      }

      message.visible = isAfter(message.message.end, new Date());
    };

    const loadMessages = () => {
      loadLoaderStateData(
        messageLoaderState,
        async () => await api.getPortalMessagesApiActive(),
        data => {
          data.forEach(message => {
            if (messages[message.id]) {
              messages[message.id].message = message;
            } else {
              messages[message.id] = {
                visible: !hiddenIds.includes(message.id),
                icon: iconForLevel(message.level),
                message,
              };
            }

            checkVisible(messages[message.id]);
          });

          // Clear message no longer in response
          const messageIds = data.map(d => d.id);
          Object.keys(messages).forEach(key => {
            const id = Number(key);
            if (!messageIds.includes(id)) {
              delete messages[id];
            }
          });

          // Remove old ids from hidden id list
          hiddenIds = hiddenIds.filter(id => messageIds.includes(id));
          setInLocalStorage(STORAGE_KEY, JSON.stringify(hiddenIds));
        },
      ).finally(() => startInterval());
    };

    watch(isAuthenticated, () => {
      if (isAuthenticated.value) {
        // Reload messages to retrieve private ones
        loadMessages();
      } else {
        // Clear private messages when no longer authenticated
        Object.values(messages).forEach(message => {
          if (message.message.private) {
            delete messages[message.message.id];
          }
        });
      }
    });

    onMounted(() => loadMessages());

    onBeforeUnmount(() => {
      clearInterval(visibleInterval);
      clearInterval(pollInterval);
    });

    return {
      messages,
      visibleItems: computed(() => Object
        .values(messages)
        .filter(m => m.visible)
        .sort((a, b) => {
          if (a.message.level === b.message.level) {
            return b.message.id - a.message.id;
          }

          return numberForLevel(b.message.level) - numberForLevel(a.message.level);
        })
      ),
      hideItem: (item: PortalMessageItem) => {
        item.visible = false;
        hiddenIds.push(item.message.id);
        setInLocalStorage(STORAGE_KEY, JSON.stringify(hiddenIds));
      },
    };
  },
});
</script>
