import type {AxiosInstance} from 'axios';
import {type Ref, ref, watch} from 'vue';
import {useConfiguration} from '../config/config';
import {useHttp} from '../http/http';
import {getCurrentLocale} from '../i18n/i18n';

export interface ValidationError {
  code?: string;
  message?: string;
  path?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function prepareApiInstance<T>(api: { new(...args: any[]): T }, http?: AxiosInstance): () => T {
  const config = useConfiguration();
  http ??= useHttp();

  // eslint-disable-next-line new-cap
  return () => new api(undefined, config.getApiEndpoint(), http);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createApiInstance<T>(api: { new(...args: any[]): T }, http?: AxiosInstance): T {
  return prepareApiInstance(api, http)();
}

export function useApiErrors(): Ref<ValidationError[]> {
  const currentLocale = getCurrentLocale();
  const apiErrors = ref<ValidationError[]>([]);

  watch(currentLocale, () => {
    // Clear api errors whenever the locale is changed, as they would have the wrong language now
    apiErrors.value = [];
  });

  return apiErrors;
}
